<template>
  <v-container fluid>
    <v-overlay v-if="progress">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-form ref="form" v-model="valid" class="ma-4">
      <!-- タイトル -->
      <v-row>
        <v-col class="pt-0 pb-0">
          <v-text-field
            v-model="menu.name"
            placeholder="メニューの名前"
            persistent-placeholder
            outlined
            :rules="rules.name"
          >
            <template v-slot:label>
              名前<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <!-- 説明 -->
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-textarea
            v-model="menu.about"
            label="説明"
            placeholder="メニューの説明"
            persistent-placeholder
            rows="5"
            outlined
          >
            <!-- <template v-slot:label>
              説明<span class="ml-2 red--text">必須</span>
            </template> -->
          </v-textarea>
        </v-col>
      </v-row>

      <!-- カテゴリ -->
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-select
            v-model="categorySelected"
            :items="groups"
            item-value="id"
            item-text="name"
            placeholder="カテゴリ"
            label="カテゴリ"
            persistent-placeholder
            multiple
            chips
            outlined
          >
            <!-- <template v-slot:label>
              カテゴリ<span class="ml-2 red--text">必須</span>
            </template> -->
            <template v-slot:selection="{ item }">
              <v-chip>
                <span class="text-h5">{{ item.name }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <!-- オプション -->
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-select
            v-model="optionSelected"
            :items="toppings"
            item-value="toppingId"
            :item-text="createToppingPulldownText"
            label="オプション"
            placeholder="オプション"
            persistent-placeholder
            multiple
            chips
            outlined
          >
            <template v-slot:selection="{ item }">
              <v-chip>
                <span class="text-h5">{{
                  createToppingPulldownChipsText(item)
                }}</span>
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-row>
        <!-- 税有無 -->
        <v-col sm="4" class="pt-0 pb-0">
          <v-select
            v-model="tax1Selected"
            :items="tax1List"
            item-value="id"
            item-text="label"
            placeholder="税有無"
            persistent-placeholder
            outlined
            readonly
          >
            <template v-slot:label>
              税有無<span class="ml-2 red--text">必須</span>
            </template>
          </v-select>
        </v-col>

        <!-- 税区分 -->
        <v-col sm="4" class="pt-0 pb-0">
          <v-select
            v-model="tax2Selected"
            :items="tax2List"
            item-value="id"
            item-text="label"
            placeholder="税区分"
            persistent-placeholder
            outlined
            readonly
          >
            <template v-slot:label>
              税区分<span class="ml-2 red--text">必須</span>
            </template>
          </v-select>
        </v-col>

        <!-- 基本金額 -->
        <v-col sm="4" class="pt-0 pb-0">
          <v-text-field
            id="amountBase"
            v-model="menu.amountBase"
            type="number"
            class="right-input"
            placeholder="999"
            persistent-placeholder
            :rules="rules.required"
            min="0"
            max="9999999"
            oninput="if(Number(this.value) > Number(this.max)) {this.value = this.max;} else if(Number(this.value) < Number(this.min)) {this.value = this.min;}"
          >
            <template v-slot:label>
              基本金額<span class="ml-2 red--text">必須</span>
            </template>
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-spacer />

        <!-- 割引設定 -->
        <v-col sm="4" class="pt-0 pb-0">
          <v-select
            v-model="discountStatusSelected"
            :items="discountStatusList"
            item-value="id"
            item-text="label"
            placeholder="割引設定"
            label="割引設定"
            outlined
          >
          </v-select>
        </v-col>

        <!-- 割引金額 -->
        <v-col sm="4" class="pt-0 pb-0">
          <v-text-field
            id="amountDiscount"
            v-model="menu.amountDiscount"
            type="number"
            class="right-input"
            label="割引金額 (税込)"
            placeholder="0"
            persistent-placeholder
            :disabled="!showDiscountArea()"
            :filled="!showDiscountArea()"
            v-if="showDiscountArea()"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-spacer />

        <!-- 合計金額 -->
        <v-col sm="4" class="pt-0 pb-0">
          <v-row align="baseline" align-content="space-between">
            <v-col cols="auto">
              <span class="text-h4">合計</span>
            </v-col>
            <v-spacer />
            <v-col cols="auto">
              <span class="text-h1">{{ amountBaseEx }}</span>
              <span class="text-h5 ml-2">円</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- 画像 -->
      <StorageView
        v-if="storageDirPath"
        class="mt-16 mb-16"
        :storage-dir-path="storageDirPath"
        :init-select-img-url="initSelectImgUrl"
        :handle-img-selected="onImgSelected"
        :handle-img-deleted="onImgDeleted"
      />

      <!-- ヘルプ -->
      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="menu.help.question"
            label="ヘルプ (質問)"
            placeholder="小麦、卵、乳は使用していますか？"
            persistent-placeholder
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pt-0 pb-0">
          <v-text-field
            v-model="menu.help.answer"
            label="ヘルプ (回答)"
            placeholder="はい。小麦、卵と牛乳を使用しています。"
            persistent-placeholder
            outlined
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pt-0 pb-0 mt-12">
          <v-select
            v-model="provideStatusSelected"
            :items="provideStatusList"
            item-value="id"
            item-text="label"
            placeholder="提供状態"
            label="提供状態"
            outlined
          >
          </v-select>
        </v-col>
      </v-row>

      <!-- 公開設定 -->
      <v-row>
        <v-col cols="12" sm="6" class="pt-0 pb-0">
          <v-checkbox v-model="menu.publish" label="公開する" />
        </v-col>
      </v-row>

      <!-- ボタン -->
      <v-row>
        <v-col cols="12" class="pt-0 pb-0 mt-12">
          <v-btn v-show="showDeleteBtn" block text @click="onClickDeleteMenu">
            <span class="text-decoration-underline">メニューを削除する</span>
          </v-btn>
          <v-btn
            class="primary mt-4"
            large
            block
            @click="registerMenu"
            :disabled="!valid"
            >{{ btnName }}</v-btn
          >
        </v-col>
      </v-row>
    </v-form>

    <!-- snackbar -->
    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      :timeout="timeout"
      :multi-line="true"
    >
      {{ snackbarText }}
    </v-snackbar>

    <!-- dialog -->
    <v-dialog v-model="delInfo.show" max-width="400" persistent>
      <v-card>
        <v-card-title>
          すべてのカテゴリから削除されます。<br />よろしいですか？
        </v-card-title>
        <v-card-text class="pb-6 pt-12 text-center">
          <v-btn class="mr-3" text @click="delInfo.show = false">
            キャンセル
          </v-btn>
          <v-btn color="primary" text @click="executeDeleteMenu(delInfo.id)">
            削除する
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const { null2Empty } = require("../../util/commonUtil");

import { DISCOUNT_STATUS_ID_NOTHING } from "../../constants";

export default {
  name: "Menu",
  components: {
    StorageView: () => import("./components/upload/StorageView"),
  },
  data: () => ({
    editMode: false, // 編集モード(true:編集 false:新規)

    // form
    valid: true,
    rules: {
      required: [(v) => !!v || "必須入力です"],
      name: [
        (v) => !!v || "必須入力です",
        (v) => (v && v.length <= 30) || "30文字以内で入力してください",
      ],
    },

    menuId: null,
    menu: {
      help: {},
      publish: true, // 公開設定
    },
    btnName: "",
    showDeleteBtn: false,

    delInfo: { show: false, id: null },

    discountStatusSelected: DISCOUNT_STATUS_ID_NOTHING,
    provideStatusSelected: 0,
    categorySelected: [],
    tax1Selected: 0,
    tax1List: [
      { id: 0, label: "税込" },
      { id: 1, label: "税抜" },
    ],
    tax2Selected: 1,
    tax2List: [
      { id: 0, label: "10%" },
      { id: 1, label: "8% 軽減" },
    ],
    optionSelected: [],

    snackbar: false,
    snackbarColor: "info",
    snackbarText: "",
    timeout: 1000,

    // StorageView
    storageDirPath: null,
    initSelectImgUrl: null,
    currentSelectedImg: null,
  }),
  computed: {
    ...mapGetters([
      "progress",
      "discountStatusList",
      "provideStatusList",
      "groups",
      "toppings",
    ]),
    showDiscountArea: function () {
      return function () {
        return this.isDiscountMenu();
      };
    },
    amountBaseEx: function () {
      let amountBase = this.menu.amountBase ?? 0;
      if (amountBase === "") {
        amountBase = 0;
      }
      if (this.showDiscountArea()) {
        const amountDiscount = this.menu.amountDiscount ?? 0;
        return amountBase - amountDiscount;
      } else {
        return amountBase;
      }
    },
  },
  created() {},
  async mounted() {
    // query
    const menuId = this.$route.params.menuId;
    console.log(`[mounted]menuId=${menuId}`);

    // 編集モード
    this.editMode = menuId ? true : false;

    // menuId
    this.menuId = this.editMode ? menuId : await this.createMenuId();

    // 選択肢
    this.fetchDefaultValuesForMenuList();
    this.fetchGroups();
    this.fetchToppings();

    // 初期表示
    this.editMode ? this.initEditMode() : this.initNewMode();
  },
  methods: {
    ...mapActions([
      "fetchDefaultValuesForMenuList",
      "fetchGroups",
      "fetchToppings",
      "fetchMenu",
      "createMenu",
      "updateMenu",
      "deleteMenu",
      "getStoragePath",
    ]),
    ...mapActions("imageUpload", ["createMenuId"]),
    async initNewMode() {
      // カテゴリ
      const groupId = this.$route.query.groupId;
      console.log(`[mounted]groupId=${groupId}`);
      if (groupId) {
        this.categorySelected = [groupId];
      }

      // storage view
      await this.initStorageView();

      this.btnName = "メニューを登録する";
      this.showDeleteBtn = false;
    },
    async initEditMode() {
      // メニュー
      let menu = await this.fetchMenu({ menuId: this.menuId });
      if (!menu.help) {
        menu.help = {};
      }
      this.menu = menu;
      console.log("[edit]menu", menu);

      // カテゴリ
      // this.categorySelected = [menu.groupId];
      this.categorySelected = menu.groupIds; // 複数対応

      // オプション
      let toppingIds = [];
      if (menu.toppings) {
        toppingIds = menu.toppings.map((t) => t.toppingId);
      }
      this.optionSelected = toppingIds;

      // 割引設定
      const discountStatusSelected =
        menu.discountStatus != null ? menu.discountStatus : 0;
      this.discountStatusSelected = discountStatusSelected;

      // 提供状態
      const provideStatusSelected =
        menu.provideStatus != null ? menu.provideStatus : 0;
      this.provideStatusSelected = provideStatusSelected;

      // storage view
      await this.initStorageView(menu);

      this.btnName = "メニューを更新する";
      this.showDeleteBtn = true;
    },
    isDiscountMenu() {
      return this.discountStatusSelected !== DISCOUNT_STATUS_ID_NOTHING;
    },
    async initStorageView(menu) {
      const storagePath = await this.getStoragePath({ menuId: this.menuId });
      console.log("[initStorageView]", storagePath);
      this.storageDirPath = storagePath;
      this.initSelectImgUrl = menu?.image_url ?? null;
    },
    async registerMenu() {
      //TODO validation
      // 入力チェック
      const submit = this.$refs.form.validate();
      if (!submit) {
        return;
      }

      // 登録データ作成
      // groupId: category 選択値(id)
      // menuId: 本画面で新規作成 or 引継(query)
      // name: 入力値
      // about: 入力値
      // amountBase: 入力値
      // help: 入力値(question/answer)
      // date: null(サーバ側で格納)
      // provideStatus: null(サーバ側で格納)
      // sorts: null(サーバ側で格納)
      // stock: null(サーバ側で格納)
      // image_url: 選択画像 URL
      // t_image_url: 選択画像 URL --> 裏でサムネイル作成+DB更新が理想
      // provideStatus: 入力値
      console.log(`menu=${JSON.stringify(this.menu)}`);
      console.log(`category=${JSON.stringify(this.categorySelected)}`);
      console.log(`option=${JSON.stringify(this.optionSelected)}`);

      try {
        // 割引金額
        const amountDiscount = this.isDiscountMenu()
          ? this.menu.amountDiscount ?? 0
          : 0;

        // 選択画像
        const selectedImgUrl = this.currentSelectedImg?.src ?? null;
        let imageUrl = null;
        let tImageUrl = null;
        if (selectedImgUrl) {
          imageUrl = selectedImgUrl;
          tImageUrl = selectedImgUrl;
        }

        // カテゴリ
        const groupIds = this.categorySelected;

        // オプション
        const toppings = this.createSelectedToppings();

        // 公開設定
        const publish = this.menu.publish ? true : false;

        const dbData = {
          menuId: this.menuId,
          groupIds: groupIds,
          name: this.menu.name,
          about: null2Empty(this.menu.about),
          amountBase: Number(this.menu.amountBase), // 基本金額
          amountBaseEx: Number(this.amountBaseEx), // 基本金額 (割引考慮)
          amountDiscount: Number(amountDiscount), // 割引金額
          discountStatus: this.discountStatusSelected, // 割引状態
          help: this.menu.help,
          image_url: imageUrl,
          t_image_url: tImageUrl,
          sorts: this.menu.sorts,
          toppings: toppings,
          provideStatus: this.provideStatusSelected,
          publish: publish,
        };
        console.log(`[register]dbData=${JSON.stringify(dbData)}`);

        // 登録処理
        if (this.editMode) {
          await this.updateMenu({ menu: dbData });
        } else {
          await this.createMenu({ menu: dbData });
        }

        // 画面遷移(メニュー一覧)
        this.$router.push(`/menu`);
      } catch (e) {
        this.showSnackbar(
          "更新に失敗しました。時間をおいてもう一度お試しください。",
          "error"
        );
        console.error(e);
      }
    },
    createToppingPulldownText(item) {
      if (item.memo) {
        return `${item.name} (${item.memo})`;
      } else {
        return `${item.name}`;
      }
    },
    createToppingPulldownChipsText(item) {
      if (item.memo) {
        return `${item.name} (${item.memo})`;
      } else {
        return `${item.name}`;
      }
    },
    createSelectedToppings() {
      let toppings = [];
      if (this.optionSelected.length != 0) {
        toppings = this.optionSelected
          .map((optionId) => {
            const t = this.toppings.find((t) => t.toppingId == optionId);
            if (!t) {
              return null;
            }
            return {
              toppingId: t.toppingId,
              toppingRef: t.toppingRef,
            };
          })
          .filter((topping) => topping != null);
        // console.log(`toppings(selected)=${JSON.stringify(toppings)}`);
      }
      return toppings;
    },
    onClickDeleteMenu() {
      // 確認ダイアログ表示
      this.delInfo = { show: true, id: this.menuId };
    },
    async executeDeleteMenu(menuId) {
      if (!menuId) {
        throw Error("not implemented");
      }
      await this.deleteMenu({ menuId: menuId });

      // 画面遷移(メニュー一覧)
      this.$router.push(`/menu`);
    },
    showSnackbar(text, color, timeout) {
      this.snackbarText = text;
      this.snackbarColor = color ? color : "warning"; // info/success/warning/error/primary/pink darken-1
      this.timeout = Number.isInteger(timeout) ? timeout : 5000;
      this.snackbar = true;
    },
    //===============
    // storage view
    //===============
    onImgSelected(imgData) {
      // 以下のタイミングで呼び出される
      // - 初期表示完了後 (初期選択画像 設定後)
      // - 画像選択時
      console.log(`[onImgSelected]${JSON.stringify(imgData)}`);
      this.currentSelectedImg = imgData;
    },
    onImgDeleted(imgData) {
      // 以下のタイミングで呼び出される
      // - 画像削除時
      console.log(`[onImgDeleted]${JSON.stringify(imgData)}`);
      this.currentSelectedImg = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.v-size--large {
  font-size: 1.4rem;
}
</style>
